import ApiClient, { RequestType } from './ApiClient';
import { IUserJson } from '../Model/User';

const UserClient = Object.freeze({

  listAllUsers: async () => {
      return await ApiClient.request(RequestType.GET, `/users/list`) as IUserJson[];
  },

});

export default UserClient;
