import ApiClient, { RequestType } from './ApiClient';
import { IAppJson } from '../Model/App';

const AppClient = Object.freeze({

  lookupApp: async (itunesId: string, os: string) => {
    return await ApiClient.request(RequestType.GET, `/admin/apps/lookup?itunesId=${itunesId}&os=${os}`) as IAppJson;
  },

  listUnpublishedApps: async () => {
    return await ApiClient.request(RequestType.GET, `/admin/apps/unpublished`) as IAppJson[];
  },

  createApp: async (itunesId: string, os: string) => {
    const payload = {
      itunesId,
      os,
    };
    return await ApiClient.request(RequestType.POST, `/admin/apps`, payload) as IAppJson;
  },

  publishApp: async (appId: string) => {
    const payload = {
      isPublished: true,
    };
    return await ApiClient.request(RequestType.POST, `/admin/apps/${appId}/publish`, payload) as IAppJson;
  },

});

export default AppClient;
