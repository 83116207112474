import * as React from 'react';
import { AppType } from '../Model/App';
import './Devices.css';

// const IphoneIcon = '/static/iphone.svg';
// const IpadIcon = '/static/ipad.svg';

interface IProps {
    type: AppType;
}

export default function Devices(props: IProps) {
    const { type } = props;
    return (
        <div className="Devices">{type}</div>
    );
    // const nodes: React.ReactNode[] = [];
    // let labelNode: React.ReactNode;
    // switch (type) {
    //     case AppType.iPhone:
    //         nodes.push(<img alt="iPhone" key="phone" src={IphoneIcon}/>);
    //         labelNode = <span key="label">iPhone</span>;
    //         break;
    //     case AppType.iPad:
    //         nodes.push(<img alt="iPad" key="pad" src={IpadIcon}/>);
    //         labelNode = <span key="label">iPad</span>;
    //         break;
    //     case AppType.universal:
    //         nodes.push(<img alt="iPhone" key="phone" src={IphoneIcon}/>);
    //         nodes.push(<img alt="iPad" key="pad" src={IpadIcon}/>);
    //         labelNode = <span key="label">Universal</span>;
    //         break;
    //     case AppType.android:
    //     default:
    //         return null;
    // }
    // return (
    //     <div className="Devices">
    //         <div className="DeviceIcons">
    //             {nodes}
    //         </div>
    //         {labelNode}
    //     </div>
    // );
}
