import { normalize } from '../Utilities/StringHelpers';
import * as numeral from 'numeral';

export enum AppType {
    iPhone = 'iphone',
    iPad = 'ipad',
    universal = 'universal',
    android = 'android',
}

export enum AppOS {
    iOS = 'ios',
    android = 'android',
}

export interface IAppJson {
    averageRating?: number;
    iconUrl?: string;
    id: string;
    itunesId: number;
    masterGameId: string;
    name: string;
    description?: string;
    initialReleaseDate?: string;
    price?: number;
    previousPrice?: number;
    priceChangedAt?: string;
    ratingCount?: number;
    type: AppType;
    os: AppOS;
    webUrl?: string;
}

export default class App {

    public readonly nameNormalized: string;

    static fromJson(json: IAppJson): App {
        const app = new App(
            json.id,
            json.name,
            json.description,
            json.itunesId,
            json.masterGameId,
            json.type,
            json.os,
            json.price,
            json.ratingCount,
            json.averageRating,
            json.iconUrl,
            json.webUrl,
            json.previousPrice,
            json.priceChangedAt && new Date(json.priceChangedAt),
            json.initialReleaseDate && new Date(json.initialReleaseDate),
        );
        return app;
    }

    public readonly saleAmount: number;

    constructor(public readonly id: string,
                public readonly name: string,
                public readonly description: string,
                public readonly itunesId: number,
                public readonly gameId: string,
                public readonly type: AppType,
                public readonly os: AppOS,
                public readonly price?: number,
                public readonly ratingCount?: number,
                public readonly averageRating?: number,
                public readonly iconUrl?: string,
                public readonly webUrl?: string,
                public readonly previousPrice?: number,
                public readonly priceChangedAt?: Date,
                public readonly initialReleaseDate?: Date,
    ) {
        this.nameNormalized = normalize(name);

        if (previousPrice !== undefined && price !== undefined) {
            // Don't allow saleAmount to be negative, just clamp to 0
            this.saleAmount = Math.max(previousPrice - price, 0);
        } else {
            this.saleAmount = 0;
        }
    }

    get itunesLink(): string {
        switch (this.os) {
            case AppOS.android:
                return `https://play.google.com/store/apps/details?id=${this.itunesId}`;
            case AppOS.iOS:
            default:
                return `http://itunes.apple.com/app/id${this.itunesId}`;
        }
    }

    get formattedPrice(): string {
        return this.formatPrice(this.price);
    }

    get formattedPreviousPrice(): string {
        return this.formatPrice(this.previousPrice);
    }

    private formatPrice(aPrice: number | undefined): string {
        if (!aPrice|| aPrice === 0) {
            return 'Free';
        }
        return numeral(aPrice / 100).format('$0,0.00');
    }
}

export enum AppSortOption {
    Name = 'Name',
    NumberOfReviews = 'Reviews',
    Price = 'Price',
    Rating = 'Rating',
    ReleaseDate = 'Release',
    Sale = 'Sale',
}
export const nameForAppSortOption = (option: AppSortOption): string => {
    switch (option) {
        case AppSortOption.Name:
            return 'Name';
        case AppSortOption.NumberOfReviews:
            return '# Reviews';
        case AppSortOption.Price:
            return 'Price';
        case AppSortOption.Rating:
            return 'Rating';
        case AppSortOption.ReleaseDate:
            return 'Release Date';
        case AppSortOption.Sale:
        default:
            return 'On Sale';

    }
};
