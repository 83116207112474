import * as React from 'react';
import * as classNames from 'classnames';
import { AppOS, } from '../Model/App';
import './Price.css';
import App from '../Model/App';

// const DownloadIOSIcon = '/static/download-ios.svg';
// const DownloadAndroidIcon = '/static/download-android.svg';

interface IProps {
    app: App;
    collapseDownload: boolean;
}

export default function Price(props: IProps) {
    const { app, collapseDownload } = props;

    let saleContainer: React.ReactNode | undefined = undefined;
    if (app.previousPrice > app.price) {
        saleContainer = (
            <span className="PreviousPriceContainer">
                <div className="SaleLabel">Sale!</div>
                <div className="PreviousPrice">{app.formattedPreviousPrice}</div>
            </span>
        );
    }

    const appStoreText = app.os === AppOS.android ? 'Play Store' : 'App Store';
    // const downloadIcon = app.os === AppOS.android ? DownloadAndroidIcon : DownloadIOSIcon;
    const downloadButton = (
        <div className="DownloadIcon" onClick={(e) => {
            e.stopPropagation();
        }}>
            <a href={app.itunesLink} target="_blank" rel="noopener">
                {appStoreText}
                {/* <img src={downloadIcon} alt="App Store"/> */}
            </a>
        </div>
    );

    return (
        <div className={classNames('Price', {CollapseDownload: collapseDownload})}>
            <span className="Amount">{app.formattedPrice}</span>
            {saleContainer}
            {downloadButton}
        </div>
    );
}
