import * as classNames from 'classnames';
import * as React from 'react';
import * as marked from 'marked';
// import App, { IAppJson } from '../Model/App';
import './AppDetail.css';
import Ratings from './Ratings';
import Devices from './Devices';
import Price from './Price';
import App from '../Model/App';

marked.setOptions({
    renderer: new marked.Renderer(),
    gfm: true,
    breaks: true,
    sanitize: true,
});

interface IProps {
    app: App;
}

class AppDetail extends React.Component<IProps> {

    getMarkdownText = (app: App) => {
        const rawMarkup = marked(app.description);
        return {
            __html: rawMarkup,
        };
    }

    render() {
        const { app } = this.props;

        return (
            <div className="BodyContainer">
                <div className="AppDetailPage">
                    <div className="Title">{app.name}</div>
                    <div className="AppDetailCellContainer">
                        {app.iconUrl && (<img className="AppIcon" src={app.iconUrl} alt={app.name}/>)}
                        <div className="CenterContent">
                            <div className="Title">{app.name}</div>
                            <Ratings rating={app.averageRating} ratingCount={app.ratingCount}/>
                            <Devices type={app.type}/>
                        </div>
                        <Price app={app} collapseDownload={false}/>
                    </div>
                    <div
                        className="Description"
                        dangerouslySetInnerHTML={this.getMarkdownText(app)}
                    />
                </div>
            </div>
        )
    }
}

export default AppDetail;
