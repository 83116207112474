export interface IUpdateUserJson {
    notifyNewAppIos?: boolean;
    notifyNewAppAndroid?: boolean;
    notifyPriceChangeIos?: boolean;
    notifyPriceChangeAndroid?: boolean;
    name?: string;
    username?: string | undefined;
}

export interface IUserJson extends IUpdateUserJson{
    id: string;
    name?: string;
    email: string;
    username: string;
    createdAt: string;
}

export default class User {

    static fromJson(json: IUserJson): User {
        const user = new User(
            json.id,
            json.name,
            json.email,
            json.username,
            json.createdAt,
            json.notifyNewAppIos,
            json.notifyNewAppAndroid,
            json.notifyPriceChangeIos,
            json.notifyPriceChangeAndroid,
        );
        return user;
    }

    constructor(
        public readonly id: string,
        public readonly name: string | undefined,
        public readonly email: string,
        public readonly username: string | undefined,
        public readonly createdAt: string,
        public readonly notifyNewAppIos: boolean = false,
        public readonly notifyNewAppAndroid: boolean = false,
        public readonly notifyPriceChangeIos: boolean = false,
        public readonly notifyPriceChangeAndroid: boolean = false,
    ) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.username = username;
        this.createdAt = createdAt;
        this.notifyNewAppIos = notifyNewAppIos;
        this.notifyNewAppAndroid = notifyNewAppAndroid;
        this.notifyPriceChangeIos = notifyPriceChangeIos;
        this.notifyPriceChangeAndroid = notifyPriceChangeAndroid;
    }

}
