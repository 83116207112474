import React, { Component } from 'react';
import User from '../Model/User';
import UserClient from '../API/UserClient';
import UserCell from '../Components/UserCell';
import App from '../Model/App';
import AppClient from '../API/AppClient';
import AppDetail from '../Components/AppDetail';
import './PublishApps.css';

interface IState {
  apps: App[] | undefined;
}

export default class PublishApps extends Component<{}, IState> {

  state: IState = {
    apps: undefined,
  }

  componentDidMount() {
    this.fetchApps();
  }

  private fetchApps = async () => {
    const appsJson = await AppClient.listUnpublishedApps();
    const apps = appsJson.map(App.fromJson);
    this.setState({
      apps,
    });
  }

  private publishApp = async (app: App) => {
    this.setState({
      apps: undefined,
    });
    await AppClient.publishApp(app.id);
    await this.fetchApps();
  }

  render() {
    const { apps } = this.state;
    
    if (apps === undefined) {
      return (
        <div>
          <h1>Unpublished apps</h1>
          <p>fetching...</p>
        </div>
      );
    }

    return (
      <div className="PublishApps">
        <h1>Unpublished apps</h1>
        <p>{apps.length} apps</p>
        { apps.map(app => {
            return (
              <div key={app.id} className="AppContainer">
                <div 
                  className="AdminButton"
                  onClick={() => { this.publishApp(app) }}
                >
                  Publish App
                </div>
                <AppDetail app={app} />
              </div>
            )
        })}
      </div>
    );
  }
}
