import * as React from 'react';

// const StarIcon = '/static/star.svg';
// const HalfStarIcon = '/static/half-star.svg';

interface IProps {
    rating: number;
}

export default function Stars(props: IProps) {
    const { rating } = props;

    return (
        <div className="Stars">
            {rating} stars
        </div>
    );

    // const numberOfFullStars = Math.floor(rating);
    // let stars: React.ReactNode[] = [];
    // for (let i = 0; i < numberOfFullStars; i++) {
    //     stars.push(<img key={i} src={StarIcon} alt={`Rating Star ${i}`} />);
    // }
    // if (rating % 1 > 0) {
    //     stars.push(<img key={'half'} src={HalfStarIcon} alt="Half Rating Star" />);
    // }

    // return (
    //     <div className="Stars">
    //         {stars}
    //     </div>
    // );
}
