import React from 'react';
import moment from 'moment';
import User from '../Model/User';
import './UserCell.css';

interface IProps {
  user: User;
}

export default function UserCell(props: IProps) {
  const { user } = props;
  return (
    <div className="UserCell">
      <div className="Email">
        {user.email}
      </div>
      <div className="Username"><a target="_blank" href={`https://boardgamer.app/user/${user.username}`}>
        {user.username}
      </a></div>
      <div className="CreatedAt">
        {moment(user.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
      </div>
    </div>
  );
}