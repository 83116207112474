import Cookies from 'universal-cookie';
import * as abab from 'abab';
import { IUserJson } from '../Model/User';
import ErrorNames, { IRequestError } from '../Utilities/Errors';

const cookies = new Cookies();
const UserIdKey = 'User.id';
const AuthTokenKey = 'Authentication.token';
const RefreshTokenKey = 'Authentication.refreshToken';
export type AuthenticationTokens = {
    authToken: string;
    refreshToken: string;
}

function cookieDomain(): string {
  const { location } = document;
  if (!location) {
      return 'localhost';
  }
  const rootDomain = location.hostname.split('.').reverse().splice(0, 2).reverse().join('.');
  if (rootDomain === 'localhost') {
      return rootDomain;
  }
  const full = '.' + rootDomain;
  return full;
}

function expirationDate(): Date {
  const date = new Date();
  date.setDate(date.getDate() + 365 * 2);
  return date;
}

function saveCookie(key: string, value: string) {
  cookies.set(key, value, {
      domain: cookieDomain(),
      path: '/',
      expires: expirationDate(),
      secure: process.env.NODE_ENV === 'production',
  });
}

function deleteCookie(key: string) {
  cookies.remove(key, {
    domain: cookieDomain(),
    path: '/',
    secure: process.env.NODE_ENV === 'production',
  });
}

enum RequestType {
    GET = 'get',
    PUT = 'put',
    POST = 'post',
    DELETE = 'delete',
    HEAD = 'head',
}

class ApiClient {
    private static get ClientIDAuthHeader(): string {
        const clientId = process.env.REACT_APP_CLIENT_ID || '07a8af36-b74e-4311-b28a-7e07518b1c9f';
        const clientSecret = process.env.REACT_APP_CLIENT_SECRET || '98c5ab4f-d236-40c1-8efb-5687ca9ff2d3';
        return 'Basic ' + abab.btoa(`${clientId}:${clientSecret}`);
    }

    private static get ProxyUrl(): string {
        return 'https://api.boardgamer.app';
        // return 'http://api-staging.boardgamer.app';
        // return '/api';
    }

    static isLoggedIn(): boolean {
        return cookies.get(RefreshTokenKey) !== undefined;
    }

    static currentUserId(): string {
        return cookies.get(UserIdKey);
    }

    static async login(email: string, password: string) {
        try {
            const requestURL = `${ApiClient.ProxyUrl}/oauth/token`;
            const data = new FormData();
            data.append('email', email);
            data.append('password', password);
            data.append('grant_type', 'password');
            const response = await fetch(requestURL, {
                method: 'post',
                body: data,
                headers: {
                    'Authorization': ApiClient.ClientIDAuthHeader,
                },
            });

            const responseJSON = await response.json();
            if (responseJSON.access_token) {
                saveCookie(AuthTokenKey, responseJSON.access_token);
                saveCookie(RefreshTokenKey, responseJSON.refresh_token);
                saveCookie(UserIdKey, responseJSON.user_id);
            } else if (responseJSON.statusCode === 401) {
                throw new Error('Invalid email/password. Please try again.');
            } else {
                throw new Error(responseJSON.message);
            }
        } catch (error) {
            ApiClient.logout();
            throw error;
        }
    }

    static async clientReauthenticateIfNecessary() {
        try {
            const refreshToken = cookies.get(RefreshTokenKey);
            if (refreshToken) {
                const authToken = cookies.get(AuthTokenKey);
                if (authToken === undefined) {
                    return await ApiClient.reauthorize(refreshToken);
                }
            }
        } catch (e) {
            console.error('clientReauthenticateIfNecessary error');
            console.error(e);
        }
    }

    private static async reauthorize(refreshToken: string): Promise<AuthenticationTokens> {
        const requestUrl = `${ApiClient.ProxyUrl}/oauth/token`;
        const data = {
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
        };
        const headers = {
            'Authorization': ApiClient.ClientIDAuthHeader,
        };
        const res = await fetch(requestUrl, {method: RequestType.POST, body: JSON.stringify(data), headers});
        if (res.status !== 200) {
            throw new Error(res.statusText);
        }
        const json = await res.json();
        if (json.access_token && json.refresh_token) {
            saveCookie(AuthTokenKey, json.access_token);
            saveCookie(RefreshTokenKey, json.refresh_token);
            saveCookie(UserIdKey, json.user_id);
            return {
                authToken: json.access_token,
                refreshToken: json.refresh_token,
            };
        } else {
            throw new Error('malformed response from server');
        }
    }

    static logout() {
        deleteCookie(AuthTokenKey);
        deleteCookie(RefreshTokenKey);
    }

    static async fetchAuthenticatedUser(): Promise<IUserJson> {
        const userJson = await ApiClient.request(RequestType.GET, `/users/me`) as IUserJson;
        saveCookie(UserIdKey, userJson.id);
        return userJson;
    }

    static async request(
        method: RequestType,
        path: string,
        data: Object | undefined = undefined,
        authTokenOverride: string | undefined = undefined,
        retryCount: number = 3
    ): Promise<Object> {
        const requestUrl = `${ApiClient.ProxyUrl}${path}`;
        try {
            const headers: { [index: string]: string } = {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            };

            const authToken = authTokenOverride || cookies.get(AuthTokenKey);
            if (authToken !== undefined) {
                headers['Authorization'] = `Bearer ${authToken}`;
            }

            const res = await fetch(requestUrl, { method, body: data ? JSON.stringify(data) : undefined, headers });

            if (res.status === 204) {
                return {};
            } else if (res.status >= 200 && res.status < 400) {
                return res.json();
            } else {
                const error = new Error(res.statusText) as IRequestError;
                error.name = ErrorNames.requestError;
                try {
                    const body = await res.json();
                    error.response = body;
                } catch {
                    // suppress parsing errors
                }
                throw error;
            }
        } catch (err) {
            const refreshToken = cookies.get(RefreshTokenKey);
            if (err.message === 'Unauthorized' && retryCount > 0 && refreshToken) {
                const tokens = await ApiClient.reauthorize(refreshToken);
                return await ApiClient.request(method, path, data, tokens.authToken, retryCount - 1);
            }
            throw err;
        }
    }
}

export default ApiClient;
export { RequestType, };
