import React, { Component } from 'react';
import User from '../Model/User';
import UserClient from '../API/UserClient';
import UserCell from '../Components/UserCell';

interface IState {
  users: User[];
}

export default class ListUsers extends Component<{}, IState> {

  state: IState = {
    users: [],
  }

  async componentDidMount() {
    const usersJson = await UserClient.listAllUsers();
    const users = usersJson.map(User.fromJson);
    this.setState({
      users,
    });
  }

  render() {
    const { users } = this.state;
    return (
      <div>
        <h1>Users</h1>
        <p>{users.length} users</p>
        { users.map(user => <UserCell key={user.id} user={user} />)}
      </div>
    );
  }
}
