import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ListUsers from "./Pages/ListUsers";
import SubmitApp from "./Pages/SubmitApp";
import PublishApps from "./Pages/PublishApps";
import Routes from "./Utilities/Routes";

function Index() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}

function AppRouter() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to={Routes.listUsers}>Users</Link>
            </li>
            <li>
              <Link to={Routes.submitApp}>Submit App</Link>
            </li>
            <li>
              <Link to={Routes.publishApps}>Publish Apps</Link>
            </li>
          </ul>
        </nav>

        <Route path="/" exact component={Index} />
        <Route path={Routes.submitApp} component={SubmitApp} />
        <Route path={Routes.publishApps} component={PublishApps} />
        <Route path={Routes.listUsers} component={ListUsers} />
      </div>
    </Router>
  );
}

export default AppRouter;