import * as React from 'react';
import Stars from './Stars';
import './Ratings.css';

interface IProps {
    rating: number | undefined;
    ratingCount: number | undefined;
}

export default function Ratings(props: IProps) {
    const { rating, ratingCount } = props;
    if (!ratingCount) {
        return null;
    }

    const ratingCountString = `(${ratingCount.toLocaleString()})`;
    return (
        <div className="Ratings">
            <Stars rating={rating}/>
            <div className="RatingCount">{ratingCountString}</div>
        </div>
    );
}
