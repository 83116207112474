import React, { Component } from 'react';
import AppClient from '../API/AppClient';
import AppDetail from '../Components/AppDetail';
import App from '../Model/App';
import Routes from '../Utilities/Routes';

interface IState {
  itunesId: string;
  iOS: boolean;
  app: App | undefined;
}

export default class SubmitApp extends Component<{}, IState> {

  state: IState = {
    itunesId: '',
    iOS: true,
    app: undefined,
  }

  private appIdChanged = (event: React.SyntheticEvent<EventTarget>) => {
    const value = (event.target as HTMLInputElement).value;
    this.setState({
      itunesId: value,
    });
  };

  private lookupApp = async () => {
    const { itunesId, iOS } = this.state;
    const os = iOS ? 'ios' : 'android';
    const appJson = await AppClient.lookupApp(itunesId, os);
    const app = App.fromJson(appJson);
    this.setState({
      app,
    });
  }

  private submitApp = async () => {
    const { itunesId, iOS } = this.state;
    const os = iOS ? 'ios' : 'android';
    await AppClient.createApp(itunesId, os);
    window.location.pathname = Routes.publishApps;
  }

  render() {
    const { itunesId, iOS, app } = this.state;
    return (
      <div className="SubmitApp">
        <h1>Submit App</h1>
        <input
            type="text"
            placeholder="App Id"
            value={itunesId}
            onChange={this.appIdChanged}
        />
        <div 
          className="AdminButton"
          onClick={this.lookupApp}
        >
          Lookup
        </div>
        { app && (
          <div 
            className="AdminButton"
            onClick={this.submitApp}
          >
            Submit
          </div>
        )}
        { app && <AppDetail app={app} /> }
      </div>
    );
  }
}
